var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('headerVue',{attrs:{"title":_vm.$t('titles.assignedEquipments')}}),_c('v-row',{attrs:{"align-content":"center","justify":"end"}},[_c('v-col',{attrs:{"md":"3"}},[_c('a',{attrs:{"href":"/asignar-equipo"}},[_c('v-btn',{attrs:{"block":"","color":"primary","elevation":"2","x-large":""}},[_vm._v("Asignar Equipo")])],1)]),_c('v-col',{attrs:{"md":"3","offset-md":"6"}},[_c('v-text-field',{attrs:{"clearable":"","append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),(_vm.validateForm)?_c('v-row',[_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"type":"error"}},[_vm._v("Todos los campos son obligatorio")])],1)],1):_vm._e(),(_vm.validateNoSerie)?_c('v-row',[_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"cols":"12"}},[_c('v-alert',{attrs:{"type":"error"}},[_vm._v("EL equipo con el Número de serie ingresado no existe")])],1)],1):_vm._e(),_c('v-row',[_c('v-col',{staticStyle:{"text-align":"center"},attrs:{"cols":"12"}},[(_vm.loading)?_c('v-progress-circular',{attrs:{"size":70,"width":7,"color":"purple","indeterminate":""}}):_vm._e(),(_vm.deleteSuccess)?_c('v-alert',{attrs:{"type":"success"}},[_vm._v("La Asignación fue eliminada")]):_vm._e()],1),(!_vm.loading)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-2 text--dark table-striped",attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.assigments,"loading-text":"Cargando datos... Por favor espere","items-per-page":5,"no-results-text":'No se encontraron incidencias',"no-data-text":'No hay datos',"footer-props":{
					'items-per-page-text': 'Equipos asignados por página',
					'items-per-page': [5, 10, 15],
					'items-per-page-all-text': 'Todos',
					'items-per-page-options': [5, 10],
					'loading-text': 'Obteniendo datos... Por favor espere',
					'no-data-text': 'No hay datos...',
					'no-results-text': 'No se encontraron incidencias',
				}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"value":true,"color":"#ffffff"}},[_c('p',{staticStyle:{"color":"#ff0000"}},[_vm._v("No hay Asignaciones aún registrados...")])])]},proxy:true},{key:"body",fn:function(ref){
				var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.id},[_c('td',{staticClass:"text-sm-center"},[_vm._v(_vm._s(item.numeroDeSerie))]),_c('td',{staticClass:"text-sm-center"},[_vm._v(_vm._s(item.nombre))]),_c('td',{staticClass:"text-sm-center"},[_vm._v(_vm._s(item.apellidoPaterno))]),_c('td',{staticClass:"text-sm-center"},[_vm._v(_vm._s(item.apellidoMaterno))]),_c('td',{staticClass:"text-sm-center"},[_vm._v(_vm._s(item.puesto))]),_c('td',{staticClass:"text-sm-center"},[_vm._v(_vm._s(item.area))]),_c('td',{staticClass:"text-sm-center"},[_vm._v(_vm._s(item.sucursal))]),_c('td',{staticClass:"justify-center layout px-0"},[_c('v-icon',{attrs:{"color":"gray"},on:{"click":function($event){return _vm.edit(item.id)}}},[_vm._v("mdi-pencil-outline")]),_c('v-icon',{attrs:{"color":"red"},on:{"click":function($event){return _vm.deleteAssigment(item.id)}}},[_vm._v("mdi-trash-can")])],1)])}),0)]}}],null,false,2393526364)})],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }