<template>
	<v-container fluid>
		<headerVue :title="$t('titles.assignedEquipments')" />

		<v-row align-content="center" justify="end">
			<v-col md="3">
				<a href="/asignar-equipo">
					<v-btn block color="primary" elevation="2" x-large>Asignar Equipo</v-btn>
				</a>
			</v-col>
			<v-col md="3" offset-md="6">
				<v-text-field clearable v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
			</v-col>
		</v-row>

		<v-row v-if="validateForm">
			<v-col cols="12" style="text-align:center;">
				<v-alert type="error">Todos los campos son obligatorio</v-alert>
			</v-col>
		</v-row>
		<v-row v-if="validateNoSerie">
			<v-col cols="12" style="text-align:center;">
				<v-alert type="error">EL equipo con el Número de serie ingresado no existe</v-alert>
			</v-col>
		</v-row>

		<v-row>
			<v-col cols="12" style="text-align:center;">
				<v-progress-circular v-if="loading"
				:size="70"
				:width="7"
				color="purple"
				indeterminate></v-progress-circular>

				<v-alert v-if="deleteSuccess" type="success">La Asignación fue eliminada</v-alert>
			</v-col>

            <v-col cols="12" v-if="!loading">
				<v-data-table
				:headers="headers"
				:search="search"
				:items="assigments"
				class="elevation-2 text--dark table-striped"
				loading-text="Cargando datos... Por favor espere"
				:items-per-page="5"
				:no-results-text="'No se encontraron incidencias'"
				:no-data-text="'No hay datos'"
				:footer-props="{
					'items-per-page-text': 'Equipos asignados por página',
					'items-per-page': [5, 10, 15],
					'items-per-page-all-text': 'Todos',
					'items-per-page-options': [5, 10],
					'loading-text': 'Obteniendo datos... Por favor espere',
					'no-data-text': 'No hay datos...',
					'no-results-text': 'No se encontraron incidencias',
				}">
					<template v-slot:no-data>
						<v-alert :value="true" color="#ffffff">
							<p style="color: #ff0000">No hay Asignaciones aún registrados...</p>
						</v-alert>
					</template>
					<template v-slot:body="{ items }">
						<tbody>
							<tr v-for="item in items" :key="item.id">
								<td class="text-sm-center">{{ item.numeroDeSerie }}</td>
								<td class="text-sm-center">{{ item.nombre }}</td>
								<td class="text-sm-center">{{ item.apellidoPaterno }}</td>
								<td class="text-sm-center">{{ item.apellidoMaterno }}</td>
								<td class="text-sm-center">{{ item.puesto }}</td>
								<td class="text-sm-center">{{ item.area }}</td>
								<td class="text-sm-center">{{ item.sucursal }}</td>
								<td class="justify-center layout px-0">
									<v-icon color="gray" @click="edit(item.id)">mdi-pencil-outline</v-icon>
									<v-icon color="red" @click="deleteAssigment(item.id)">mdi-trash-can</v-icon>
								</td>
							</tr>
						</tbody>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
import headerVue from "../../components/header.vue"
export default {
	components: {
		headerVue,
	},
	data() {
		return {
			deleteSuccess: false,
			loading: true,
			validateForm:false,
			validateNoSerie: false,
			search: "",
			headers: [
				{
					text: "nº Serie",
					align: "center",
					sortable: false,
					value: "numeroDeSerie",
				},
				{ text: "Nombre", align: "center", value: "nombre" },
				{ text: "Primer Apellido", align: "center", value: "apellidoPaterno" },
				{ text: "Segundo Apellido", align: "center", value: "apellidoMaterno" },
				{ text: "Área", align: "center", value: "area" },
				{ text: "Puesto", align: "center", value: "puesto" },
				{ text: "Sucursal", align: "center", value: "sucursal" },
				{ text: "Opciones", align: "center", sortable: false },
			],
			assigments: [],
		}
	},
	methods: {
        deleteAssigment(id) {

		  	this.deleteSuccess = true;
			this.assigments = this.assigments.filter((assigment) => assigment.id !== id);

			axios.delete( 'https://consubanco.azurewebsites.net/api/CargaEquipoComputoUsuarios/' + id )
			.then(resp => {
				
				window.location.reload();
			})
			.catch(err => {
				
				console.warn(err);
			});
        },
        edit(id) {
          
		  this.$router.push( "/editar-asignar/"+ id );
        },
        inicialization() {

            const headerCurrent = {
				'Content-type': 'application/json;',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Credentials': 'true'
			}
          
			axios.get( 'https://consubanco.azurewebsites.net/api/CargaEquipoComputoUsuarios',{
				headers: headerCurrent
			})
			.then(resp => {
				this.assigments = resp.data;
				this.loading = false;
			})
			.catch(err => {
				// Handle Error Here
				console.error(err);
			});
        },
    },
	mounted() {
		this.inicialization()
	},
}
</script>